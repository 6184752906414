import positiveAffirmations from "../docs/Unilever-DSEP-FamilyActivity-1.1-PositiveAffirmations.pdf"
import positiveAffirmationsAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-1.1-PositiveAffirmations_ES_AR-CL.pdf"
import positiveAffirmationsBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-1.1-PositiveAffirmations_Brazilian-Portuguese.pdf"
import positiveAffirmationsCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-1.1-PositiveAffirmations_COL.pdf"
import positiveAffirmationsFrench from "../docs/french/Unilever-DSEP-FamilyActivity-1.1-PositiveAffirmations_French.pdf"
import positiveAffirmationsMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-1.1-PositiveAffirmations_Mex.pdf"

import bodyImageAndMedia from "../docs/Unilever-DSEP-FamilyActivity-1.2-BodyImageAndTheMedia.pdf"
import bodyImageAndMediaAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-1.2-BodyImageAndTheMedia_ES_AR-CL.pdf"
import bodyImageAndMediaBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-1.2-BodyImageAndTheMedia_Brazilian-Portuguese.pdf"
import bodyImageAndMediaCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-1.2-BodyImageAndTheMedia_COL.pdf"
import bodyImageAndMediaFrench from "../docs/french/Unilever-DSEP-FamilyActivity-1.2-BodyImageAndTheMedia_French.pdf"
import bodyImageAndMediaMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-1.2-BodyImageAndTheMedia_Mex.pdf"

import appreciatingOurBodies from "../docs/Unilever-DSEP-FamilyActivity-1.3-AppreciatingOurBodies.pdf"
import appreciatingOurBodiesAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-1.3-AppreciatingOurBodies_ES_AR-CL.pdf"
import appreciatingOurBodiesBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-1.3-AppreciatingOurBodies_Brazilian-Portuguese.pdf"
import appreciatingOurBodiesCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-1.3-AppreciatingOurBodies_COL.pdf"
import appreciatingOurBodiesFrench from "../docs/french/Unilever-DSEP-FamilyActivity-1.3-AppreciatingOurBodies_French.pdf"
import appreciatingOurBodiesMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-1.3-AppreciatingOurBodies_Mex.pdf"

import bodyTalkInTheHome from "../docs/Unilever-DSEP-FamilyActivity-1.4-BodyTalkintheHome.pdf"
import bodyTalkInTheHomeAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-1.4-BodyTalkintheHome_ES_AR-CL.pdf"
import bodyTalkInTheHomeBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-1.4-BodyTalkintheHome_Brazilian-Portuguese.pdf"
import bodyTalkInTheHomeCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-1.4-BodyTalkintheHome_COL.pdf"
import bodyTalkInTheHomeFrench from "../docs/french/Unilever-DSEP-FamilyActivity-1.4-BodyTalkintheHome_French.pdf"
import bodyTalkInTheHomeMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-1.4-BodyTalkintheHome_Mex.pdf"

import letsThink from "../docs/Unilever-DSEP-FamilyActivity-1.5-LetsTHINK.pdf"
import letsThinkAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-1.5-LetsTHINK_ES_AR-CL.pdf"
import letsThinkBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-1.5-LetsTHINK_Brazilian-Portuguese.pdf"
import letsThinkCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-1.5-LetsTHINK_COL.pdf"
import letsThinkFrench from "../docs/french/Unilever-DSEP-FamilyActivity-1.5-LetsTHINK_French.pdf"
import letsThinkMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-1.5-LetsTHINK_Mex.pdf"

import standUpToBullying from "../docs/Unilever-DSEP-FamilyActivity-2.1-StanduptoBullying.pdf"
import standUpToBullyingAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-2.1-StanduptoBullying_ES_AR-CL.pdf"
import standUpToBullyingBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-2.1-StanduptoBullying_Brazilian-Portuguese.pdf"
import standUpToBullyingCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-2.1-StanduptoBullying_COL.pdf"
import standUpToBullyingFrench from "../docs/french/Unilever-DSEP-FamilyActivity-2.1-StanduptoBullying_French.pdf"
import standUpToBullyingMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-2.1-StanduptoBullying_Mex.pdf"

import respectfulContract from "../docs/Unilever-DSEP-FamilyActivity-2.2-TheRespectfulContract.pdf"
import respectfulContractAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-2.2-TheRespectfulContract_ES_AR-CL.pdf"
import respectfulContractBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-2.2-TheRespectfulContract_Brazilian-Portuguese.pdf"
import respectfulContractCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-2.2-TheRespectfulContract_COL.pdf"
import respectfulContractFrench from "../docs/french/Unilever-DSEP-FamilyActivity-2.2-TheRespectfulContract_French.pdf"
import respectfulContractMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-2.2-TheRespectfulContract_Mex.pdf"

import embraceDifferences from "../docs/Unilever-DSEP-FamilyActivity-2.3-EmbracingOurDifferences.pdf"
import embraceDifferencesAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-2.3-EmbracingOurDifferences_ES_AR-CL.pdf"
import embraceDifferencesBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-2.3-EmbracingOurDifferences_Brazilian-Portuguese.pdf"
import embraceDifferencesCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-2.3-EmbracingOurDifferences_COL.pdf"
import embraceDifferencesFrench from "../docs/french/Unilever-DSEP-FamilyActivity-2.3-EmbracingOurDifferences_French.pdf"
import embraceDifferencesMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-2.3-EmbracingOurDifferences_Mex.pdf"

import myBodyCan from "../docs/Unilever-DSEP-FamilyActivity-2.4-MyBodyCan.pdf"
import myBodyCanAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-2.4-MyBodyCan_ES_AR-CL.pdf"
import myBodyCanBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-2.4-MyBodyCan_Brazilian-Portuguese.pdf"
import myBodyCanCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-2.4-MyBodyCan_COL.pdf"
import myBodyCanFrench from "../docs/french/Unilever-DSEP-FamilyActivity-2.4-MyBodyCan_French.pdf"
import myBodyCanMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-2.4-MyBodyCan_Mex.pdf"

import influencers from "../docs/Unilever-DSEP-FamilyActivity-2.5-Influencers.pdf"
import influencersAR from "../docs/argentine/Unilever-DSEP-FamilyActivity-2.5-Influencers_ES_AR-CL.pdf"
import influencersBR from "../docs/brazilian-portuguese/Unilever-DSEP-FamilyActivity-2.5-Influencers_Brazilian-Portuguese.pdf"
import influencersCO from "../docs/colombian/Unilever-DSEP-FamilyActivity-2.5-Influencers_COL.pdf"
import influencersFrench from "../docs/french/Unilever-DSEP-FamilyActivity-2.5-Influencers_French.pdf"
import influencersMX from "../docs/mexican/Unilever-DSEP-FamilyActivity-2.5-Influencers_Mex.pdf"

const confrontingComparisons = [
  {
    heading: "FAMILY ACTIVITY",
    title: "Positive Affirmations",
    // subject: 'SUBJECT',
    duration: "20 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, each family member will leave affirming messages for other members of the family and discuss how seeing those notes positively affected their mood throughout the day.",
    image: "img_activity_positive_affirmations.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: positiveAffirmations,
            type: "download",
            title: "Family Activity - Positive Affirmations - English",
          },
          {
            label: "Spanish (Colombia)",
            path: positiveAffirmationsCO,
            type: "download",
            title: "Family Activity - Positive Affirmations - Colombia",
          },
          {
            label: "Spanish (Latin American)",
            path: positiveAffirmationsAR,
            type: "download",
            title:
              "Family Activity - Positive Affirmations - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: positiveAffirmationsMX,
            type: "download",
            title: "Family Activity - Positive Affirmations - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: positiveAffirmationsBR,
            type: "download",
            title:
              "Family Activity - Positive Affirmations - Brazilian Portuguese",
          },
          {
            label: "French",
            path: positiveAffirmationsFrench,
            type: "download",
            title: "Family Activity - Positive Affirmations - French",
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "Body Image and the Media",
    // subject: 'SUBJECT',
    duration: "20 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, families will identify several examples of “appearance ideals” promoted in the media by detecting photoshopping, posture tricks, & filters while looking at magazines, watching TV, browsing the internet, etc.",
    image: "img-activity-media-influence.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: bodyImageAndMedia,
            type: "download",
            title: "Family Activity - Body Image & Media - English",
          },
          {
            label: "Spanish (Colombia)",
            path: bodyImageAndMediaCO,
            type: "download",
            title: "Family Activity - Body Image & Media - Colombia",
          },
          {
            label: "Spanish (Latin American)",
            path: bodyImageAndMediaAR,
            type: "download",
            title:
              "Family Activity - Body Image & Media - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: bodyImageAndMediaMX,
            type: "download",
            title: "Family Activity - Body Image & Media - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: bodyImageAndMediaBR,
            type: "download",
            title: "Family Activity - Body Image & Media - Brazilian",
          },
          {
            label: "French",
            path: bodyImageAndMediaFrench,
            type: "download",
            title: "Family Activity - Body Image & Media - French",
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "Appreciating Our Bodies",
    // subject: 'SUBJECT',
    duration: "20 minutes",
    grade: "Grades 4—5",
    description:
      "In this activi‰ty, families will create signs of positive body affirmations to hang around the home to help each member feel comfortable and confident in their own body.",
    image: "img_activity_competing_comparing.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: appreciatingOurBodies,
            type: "download",
            title: "Family Activity - Appreciating Our Bodies - English",
          },
          {
            label: "Spanish (Colombia)",
            path: appreciatingOurBodiesCO,
            type: "download",
            title: "Family Activity - Appreciating Our Bodies - Colombia",
          },
          {
            label: "Spanish (Latin American)",
            path: appreciatingOurBodiesAR,
            type: "download",
            title:
              "Family Activity - Appreciating Our Bodies - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: appreciatingOurBodiesMX,
            type: "download",
            title: "Family Activity - Appreciating Our Bodies - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: appreciatingOurBodiesBR,
            type: "download",
            title: "Family Activity - Appreciating Our Bodies - Brazilian",
          },
          {
            label: "French",
            path: appreciatingOurBodiesFrench,
            type: "download",
            title: "Family Activity - Appreciating Our Bodies - French",
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "Body Talk in the Home",
    // subject: 'SUBJECT',
    duration: "20 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, parents will read a Body Talk Handout which discusses how adults’ language about their own bodies can affect their children. Each family member will create a list of things that they love about their physical appearance and share their ideas.",
    image: "img_activity_body_talk.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: bodyTalkInTheHome,
            type: "download",
            title: "Family Activity - Body Talk in the Home - English",
          },
          {
            label: "Spanish (Colombia)",
            path: bodyTalkInTheHomeCO,
            type: "download",
            title: "Family Activity - Body Talk in the Home - Colombia",
          },
          {
            label: "Spanish (Latin American)",
            path: bodyTalkInTheHomeAR,
            type: "download",
            title:
              "Family Activity - Body Talk in the Home - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: bodyTalkInTheHomeMX,
            type: "download",
            title: "Family Activity - Body Talk in the Home - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: bodyTalkInTheHomeBR,
            type: "download",
            title: "Family Activity - Body Talk in the Home - Brazilian",
          },
          {
            label: "French",
            path: bodyTalkInTheHomeFrench,
            type: "download",
            title: "Family Activity - Body Talk in the Home - French",
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "Let’s “THINK”!",
    // subject: 'SUBJECT',
    duration: "20 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, families will discuss the power of words and share a time that they said or posted something they later regretted. They will then do a demonstration illustrating how hurtful words cannot be “taken back,” and pledge to “THINK” before they speak or post.",
    image: "img-activity-lets-think.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: letsThink,
            type: "download",
            title: 'Family Activity - Let\'s "THINK" - English',
          },
          {
            label: "Spanish (Colombia)",
            path: letsThinkCO,
            type: "download",
            title: 'Family Activity - Let\'s "THINK" - Colombia',
          },
          {
            label: "Spanish (Latin American)",
            path: letsThinkAR,
            type: "download",
            title:
              'Family Activity - Let\'s "THINK" - Argentine/Latin American',
          },
          {
            label: "Spanish (Mexico)",
            path: letsThinkMX,
            type: "download",
            title: 'Family Activity - Let\'s "THINK" - Mexican',
          },
          {
            label: "Brazilian Portuguese",
            path: letsThinkBR,
            type: "download",
            title: 'Family Activity - Let\'s "THINK" - Brazilian',
          },
          {
            label: "French",
            path: letsThinkFrench,
            type: "download",
            title: 'Family Activity - Let\'s "THINK" - French',
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "A Plan to Stay Positive",
    // subject: 'SUBJECT',
    duration: "30 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, families will identify norms – traditions or situations where they are expected to “conform” or “fit in” by looking a certain way. Families will recognize a positive trait or characteristic about someone else and make a plan for recognizing these traits more often.",
    image: "img-activity-weight-based-bullying.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: standUpToBullying,
            type: "download",
            title: "Family Activity - A Plan to Stay Positive - English",
          },
          {
            label: "Spanish (Colombia)",
            path: standUpToBullyingCO,
            type: "download",
            title: "Family Activity - A Plan to Stay Positive - Columbia",
          },
          {
            label: "Spanish (Latin American)",
            path: standUpToBullyingAR,
            type: "download",
            title:
              "Family Activity - A Plan to Stay Positive - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: standUpToBullyingMX,
            type: "download",
            title: "Family Activity - A Plan to Stay Positive - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: standUpToBullyingBR,
            type: "download",
            title: "Family Activity - A Plan to Stay Positive - Brazilian",
          },
          {
            label: "French",
            path: standUpToBullyingFrench,
            type: "download",
            title: "Family Activity - A Plan to Stay Positive - French",
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "The Respectful Contract",
    // subject: 'SUBJECT',
    duration: "30 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, families will construct a “Respectful Contract” that outlines acceptable and unacceptable behaviors in the household. Families will discuss how these behavioral norms can be applied outside of the home, like at school or in the community.",
    image: "img-activity-bullying-in-school.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: respectfulContract,
            type: "download",
            title: "Family Activity - The Respectful Contract - English",
          },
          {
            label: "Spanish (Colombia)",
            path: respectfulContractCO,
            type: "download",
            title: "Family Activity - The Respectful Contract - Colombia",
          },
          {
            label: "Spanish (Latin American)",
            path: respectfulContractAR,
            type: "download",
            title:
              "Family Activity - The Respectful Contract - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: respectfulContractMX,
            type: "download",
            title: "Family Activity - The Respectful Contract - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: respectfulContractBR,
            type: "download",
            title: "Family Activity - The Respectful Contract - Brazilian",
          },
          {
            label: "French",
            path: respectfulContractFrench,
            type: "download",
            title: "Family Activity - The Respectful Contract - French",
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "Embracing Our Differences",
    // subject: 'SUBJECT',
    duration: "30 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, families will read aloud a series of quotes by different authors and historical figures.  Families will discuss who each person was and how their background informed their perspective and helped them embrace diversity.",
    image: "img-activity-exit-and-refusal.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: embraceDifferences,
            type: "download",
            title: "Family Activity - Embracing Our Differences - English",
          },
          {
            label: "Spanish (Colombia)",
            path: embraceDifferencesCO,
            type: "download",
            title: "Family Activity - Embracing Our Differences - Colombia",
          },
          {
            label: "Spanish (Latin American)",
            path: embraceDifferencesAR,
            type: "download",
            title:
              "Family Activity - Embracing Our Differences - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: embraceDifferencesMX,
            type: "download",
            title: "Family Activity - Embracing Our Differences - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: embraceDifferencesBR,
            type: "download",
            title: "Family Activity - Embracing Our Differences - Brazilian",
          },
          {
            label: "French",
            path: embraceDifferencesFrench,
            type: "download",
            title: "Family Activity - Embracing Our Differences - French",
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "My Body Can...",
    // subject: 'SUBJECT',
    duration: "30 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, families will discuss all of the things that their bodies allow them to do. Family members will collect images, words, or pictures to describe the things that their bodies allow them to do and create collages.",
    image: "img_activity_lets_talk_about.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: myBodyCan,
            type: "download",
            title: "Family Activity - My Body Can... - English",
          },
          {
            label: "Spanish (Colombia)",
            path: myBodyCanCO,
            type: "download",
            title: "Family Activity - My Body Can... - Colombia",
          },
          {
            label: "Spanish (Latin American)",
            path: myBodyCanAR,
            type: "download",
            title:
              "Family Activity - My Body Can... - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: myBodyCanMX,
            type: "download",
            title: "Family Activity - My Body Can... - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: myBodyCanBR,
            type: "download",
            title: "Family Activity - My Body Can... - Brazilian",
          },
          {
            label: "French",
            path: myBodyCanFrench,
            type: "download",
            title: "Family Activity - My Body Can... - French",
          },
        ],
      },
    ],
  },
  {
    heading: "FAMILY ACTIVITY",
    title: "Influencers",
    // subject: 'SUBJECT',
    duration: "60 minutes",
    grade: "Grades 4—5",
    description:
      "In this activity, families will discuss the various influencers on their body image before working together on a scavenger hunt to identify external influencers in the media. Families will construct an action plan for avoiding negative influencers & maintaining healthy attitudes.",
    image: "img-activity-media-influence.jpg",
    actions: [
      {
        label: "Family Activity",
        size: "(Select a language)",
        type: "dropdown",
        actions: [
          {
            label: "English",
            path: influencers,
            type: "download",
            title: "Family Activity - Influencers - English",
          },
          {
            label: "Spanish (Colombia)",
            path: influencersCO,
            type: "download",
            title: "Family Activity - Influencers - Colombia",
          },
          {
            label: "Spanish (Latin American)",
            path: influencersAR,
            type: "download",
            title: "Family Activity - Influencers - Argentine/Latin American",
          },
          {
            label: "Spanish (Mexico)",
            path: influencersMX,
            type: "download",
            title: "Family Activity - Influencers - Mexican",
          },
          {
            label: "Brazilian Portuguese",
            path: influencersBR,
            type: "download",
            title: "Family Activity - Influencers - Brazilian",
          },
          {
            label: "French",
            path: influencersFrench,
            type: "download",
            title: "Family Activity - Influencers - French",
          },
        ],
      },
    ],
  },
]

export { confrontingComparisons }
